@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .fade-in {
    animation: fadeIn 1s ease-out;
  }

/* in your main CSS file */
body {
  margin: 0;
  padding: 0;
}
.container {
  padding: 2rem 1rem; /* Adjust as needed */
}

/* In your CSS file */
.section-offset {
  margin-top: -4rem; /* Negative margin equal to header height */
  padding-top: 4rem; /* Padding to push down the content */
}